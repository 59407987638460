import cn from 'classnames';
import * as React from 'react';

import BaseClickable from 'components/ui/BaseClickable';
import { SocialIconView } from 'components/ui/icons/socialIcons/types';

import { SOCIAL_NETWORKS_MAP } from './config';
import { SocialNetworkType } from './types';

import s from './SocialItem.module.scss';

type SocialItemProps = {
  type: SocialNetworkType;
  view?: SocialIconView;
  className?: string;
};

const SocialItem: React.FC<SocialItemProps> = ({ type, className, view = SocialIconView.primary }) => {
  const { url, Icon } = SOCIAL_NETWORKS_MAP[type];

  return (
    <BaseClickable className={className} element="a" href={url} block>
      <Icon className={cn(s.socialItem, s[`social-item_${view}`])} view={view} />
    </BaseClickable>
  );
};

export default React.memo(SocialItem);
