import cn from 'classnames';
import * as React from 'react';

import { SocialIconProps } from '../types';

import s from './YoutubeIcon.module.scss';

export const YoutubeIcon: React.FC<SocialIconProps> = React.memo(({ view, className }) => {
  return (
    <svg
      className={cn(s.youtubeIcon, s[`youtube-icon_${view}`], className)}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className={s.youtubeIcon__background} cx="16" cy="16" r="16" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M10.8545 9.59961H21.1439C23.1627 9.59961 24.7992 11.2816 24.7992 13.3564V18.6428C24.7992 20.7176 23.1627 22.3996 21.1439 22.3996H10.8545C8.83575 22.3996 7.19922 20.7176 7.19922 18.6428V13.3564C7.19922 11.2816 8.83575 9.59961 10.8545 9.59961ZM13.8584 18.6168L18.6711 16.2577C18.8159 16.1867 18.8184 15.9758 18.6753 15.9013L13.8626 13.3948C13.7341 13.3278 13.582 13.4238 13.582 13.5719V18.4375C13.582 18.5835 13.7302 18.6796 13.8584 18.6168Z"
      />
    </svg>
  );
});
