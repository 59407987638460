import * as React from 'react';

import Typo from 'components/ui/Typo';

import s from './Copyright.module.scss';

const Copyright: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={s.root}>
      <Typo className={s.root__text} element="span" weight="bold" view="body-3" color="white">
        &copy;&nbsp;{currentYear} «Россия — страна возможностей». Все права защищены
      </Typo>
    </div>
  );
};

export default Copyright;
