import * as React from 'react';

import AppLink, { MobileAppType } from './AppLink';

import s from './AppLinks.module.scss';

const AppLinks: React.FC = () => {
  return (
    <ul className={s.appLinks}>
      {Object.values(MobileAppType).map((type) => (
        <li className={s.appLinks__item} key={type}>
          <AppLink type={type} />
        </li>
      ))}
    </ul>
  );
};

export default AppLinks;
