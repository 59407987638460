import cn from 'classnames';
import * as React from 'react';

import Spinner from 'components/ui/Spinner';

import s from './Loader.module.scss';

export type LoaderProps = {
  className?: string;
  mode?: 'relative' | 'absolute';
};

const Loader: React.FC<LoaderProps> = ({ className, mode = 'relative' }) => {
  return (
    <span className={cn(s.root, s[`root_mode-${mode}`], className)}>
      <Spinner />
    </span>
  );
};

export default React.memo(Loader);
