import cn from 'classnames';
import * as React from 'react';

import { SocialIconProps } from '../types';

import s from './VkIcon.module.scss';

export const VkIcon: React.FC<SocialIconProps> = React.memo(({ view, className }) => {
  return (
    <svg
      className={cn(s.vkIcon, s[`vk-icon_${view}`], className)}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className={s.vkIcon__background} cx="16" cy="16" r="16" fill="currentColor" />
      <path
        fill="currentColor"
        d="M6.02104 10.6523C6.12362 12.6907 6.47869 14.4739 7.09415 16.0362C7.55969 17.2224 8.18304 18.2771 8.93001 19.1477C9.23511 19.5028 9.59018 19.8579 9.93736 20.1551C11.4471 21.4465 13.3645 22.2171 15.5949 22.4223C16.0262 22.4617 16.2787 22.4749 16.8179 22.4802L17.2834 22.4854V20.255C17.2834 19.0294 17.2861 18.0246 17.2887 18.0246C17.3097 18.0246 17.5543 18.0562 17.6464 18.072C18.6879 18.2456 19.6243 18.6874 20.4423 19.3897C21.3102 20.134 22.0125 21.1914 22.3991 22.3329L22.4491 22.4802H24.2271C25.9156 22.4802 26.0051 22.4802 25.9998 22.467C25.9998 22.4591 25.9814 22.4091 25.9656 22.3513C25.5422 20.8942 24.8294 19.6001 23.8325 18.4797C23.1434 17.7038 22.2992 17.0147 21.4417 16.5202C21.2892 16.4334 21.0656 16.3124 20.9551 16.2624L20.871 16.223L20.9788 16.1572C21.7284 15.7022 22.5096 15.0263 23.1592 14.2714C23.6958 13.648 24.1324 12.9931 24.4822 12.2909C24.7689 11.7201 24.9635 11.2046 25.1187 10.6076C25.1634 10.4366 25.2476 10.0473 25.2476 10.0105C25.2476 10.0026 24.9688 10 23.6353 10H22.023L22.0098 10.05C21.9862 10.1368 21.8625 10.505 21.8126 10.6365C21.397 11.7122 20.7447 12.717 19.9452 13.4981C19.1035 14.324 18.1882 14.8185 17.3097 14.9263H17.2861V10H14.0588V14.3187C14.0588 18.0168 14.0588 18.6375 14.0483 18.6375C14.0299 18.6375 13.8063 18.5717 13.7038 18.5349C13.2409 18.3692 12.7648 18.0983 12.3597 17.7721C10.7632 16.4834 9.7664 14.2319 9.49812 11.3098C9.46919 10.9995 9.44552 10.6049 9.435 10.3288C9.435 10.2551 9.42974 10.1499 9.42711 10.0973L9.42185 10H6V10.0526C6 10.1236 6.01315 10.4655 6.02104 10.6523Z"
      />
    </svg>
  );
});
