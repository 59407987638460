import * as React from 'react';

import { MobileAppIconProps } from 'components/ui/icons/mobileAppIcons/types';

export enum MobileAppType {
  ruStore = 'ruStore',
  apple = 'appStore',
}

export type MobileApp = {
  url: string;
  Icon: React.ComponentType<MobileAppIconProps>;
};
