import { useWidth } from 'utils/devices';
import { getRem, getRemMobile, getRemTablet } from 'utils/getRem';

type SizeMap = {
  desktop: number;
  tablet?: number;
  phone?: number;
};

type CalcRem = (pixels: SizeMap) => string;

export const useRem = (): CalcRem => {
  const { isTablet, isPhone } = useWidth();

  return (px) => {
    if (isTablet) {
      return getRemTablet(px?.tablet ?? px.desktop);
    }

    if (isPhone) {
      return getRemMobile(px?.phone ?? px.tablet ?? px.desktop);
    }

    return getRem(px.desktop);
  };
};
