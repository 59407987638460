import { DzenIcon } from 'components/ui/icons/socialIcons/DzenIcon';
import { OkIcon } from 'components/ui/icons/socialIcons/OkIcon';
import { RutubeIcon } from 'components/ui/icons/socialIcons/RutubeIcon';
import { TelegramIcon } from 'components/ui/icons/socialIcons/TelegramIcon';
import { TenchatIcon } from 'components/ui/icons/socialIcons/TenchatIcon';
import { VkIcon } from 'components/ui/icons/socialIcons/VkIcon';
import { YappiIcon } from 'components/ui/icons/socialIcons/YappiIcon';
import { YoutubeIcon } from 'components/ui/icons/socialIcons/YoutubeIcon';

import { SocialNetwork, SocialNetworkType } from './types';

export const SOCIAL_NETWORKS_MAP: Record<SocialNetworkType, SocialNetwork> = {
  [SocialNetworkType.vk]: {
    url: 'https://vk.com/rsvru',
    Icon: VkIcon,
  },
  [SocialNetworkType.ok]: {
    url: 'https://www.ok.ru/RSVRU',
    Icon: OkIcon,
  },
  [SocialNetworkType.youtube]: {
    url: 'https://www.youtube.com/channel/UCJPc1Fjh9C8tPUo5qBEErMw',
    Icon: YoutubeIcon,
  },
  [SocialNetworkType.telegram]: {
    url: 'https://t.me/stranavozmojnostey',
    Icon: TelegramIcon,
  },
  [SocialNetworkType.rutube]: {
    url: 'https://rutube.ru/channel/23486710/',
    Icon: RutubeIcon,
  },
  [SocialNetworkType.yappi]: {
    url: 'https://yappy.media/profile/2e84754f849c4f488ff9e1fe870cb17d',
    Icon: YappiIcon,
  },
  [SocialNetworkType.dzen]: {
    url: 'https://dzen.ru/rossiya_strana_vozmozhnostej',
    Icon: DzenIcon,
  },
  [SocialNetworkType.tenchat]: {
    url: 'https://tenchat.ru/rsvru',
    Icon: TenchatIcon,
  },
};
