import cn from 'classnames';
import * as React from 'react';

import Typo from 'components/ui/Typo';

import s from './Notification.module.scss';

type NotificationProps = {
  className?: string;
  iconContent?: React.ReactNode;
  children: React.ReactNode;
  color: 'success' | 'error';
};

const Notification: React.FC<NotificationProps> = ({ className, iconContent, children, color }) => {
  return (
    <div className={cn(s.root, s[`root_color-${color}`], className)}>
      {iconContent && <div className={s.root__icon}>{iconContent}</div>}
      <Typo view="body-2" className={s.root__content}>
        {children}
      </Typo>
    </div>
  );
};

export default Notification;
