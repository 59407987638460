import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { Container } from 'components/ui/Container';
import { useIsMobileApp } from 'hooks/useIsMobileApp';

import Footer from './Footer';
import Header from './Header';

import s from './Layout.module.scss';

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isMobileApp = useIsMobileApp();

  return (
    <div className={cn(s.layout, isMobileApp && s['layout_mobile-app'])}>
      {!isMobileApp && <Header />}
      <Container element="main">{children}</Container>
      {!isMobileApp && <Footer className={s.layout__footer} />}
    </div>
  );
};

export default observer(Layout);
