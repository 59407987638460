import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useMatch } from 'react-router-dom';

import BaseClickable from 'components/ui/BaseClickable';
import Typo from 'components/ui/Typo';
import { useOpenStore } from 'hooks/useOpenStore';
import { useRem } from 'hooks/useRem';
import { useWidth } from 'utils/devices';

import Buttons from './Buttons';
import PictureWrapper from './PictureWrapper';
import { CloseCircleIcon } from './svgs/CloseCircleIcon';

import s from './AssistentWidget.module.scss';

const AssistentWidget: React.FC = () => {
  const calcRem = useRem();
  const { isDesktop } = useWidth();
  const widgetController = useOpenStore(true);
  const buttonsController = useOpenStore();

  React.useEffect(() => {
    const baseWidget = document.getElementById('hde-contact-container');

    baseWidget?.classList.add(s['root__base-widget']);
  }, []);

  const handleChatClick = React.useCallback(() => {
    const chatButton = document.getElementById('hde-chat-init-btn-2');

    chatButton?.click();
  }, []);

  const handleClose = React.useCallback(() => {
    widgetController.close();
    buttonsController.close();
  }, [buttonsController, widgetController]);

  const isScorm = useMatch('courses/:id/scorm');
  const isConstructor = useMatch('constructor/*');

  if (isScorm || isConstructor) {
    return null;
  }

  return (
    <div className={cn(s.root, !widgetController.isOpen && s.root_hide)}>
      <Buttons isOpen={buttonsController.isOpen} handleChatClick={handleChatClick} />
      <PictureWrapper widgetController={widgetController} buttonsController={buttonsController} />
      {widgetController.isOpen && isDesktop && (
        <Typo className={s.root__info} view="body-2" weight="medium" color="dark-purple-100">
          Привет! Я&nbsp;Вера, помощник
          <br />
          цифровой платформы.
          <br />
          Задайте свой вопрос.
        </Typo>
      )}
      {widgetController.isOpen && isDesktop && (
        <BaseClickable className={s['root__close-wrapper']} onClick={handleClose}>
          <CloseCircleIcon size={calcRem({ desktop: 26 })} />
        </BaseClickable>
      )}
    </div>
  );
};

export default observer(AssistentWidget);
