import { action, makeObservable, observable, runInAction } from 'mobx';

import { ENDPOINTS } from 'configs/endpoints';
import { ILocalStore } from 'stores/interfaces/ILocalStore';
import call from 'utils/api';

import { LegalItemsApi, LegalItem } from './types';

const LEGAL_ITEMS_REQUIRED_TYPES = new Set(['terms-of-use', 'data-processing-policy']);

type PrivateFields = '_fetchLegalItems';

export class FooterStore implements ILocalStore {
  legalItems: LegalItem[] = [];

  constructor() {
    makeObservable<this, PrivateFields>(this, {
      legalItems: observable.ref,
      _fetchLegalItems: action,
    });

    this._fetchLegalItems();
  }

  private async _fetchLegalItems(): Promise<boolean> {
    const response = await call<LegalItemsApi>({ endpoint: ENDPOINTS.rsv.contacts });

    if (response.isError) {
      return false;
    }

    runInAction(() => {
      const items = response.data.list[0].data.docs;

      this.legalItems = items.reduce<LegalItem[]>((acc, item) => {
        if (item.type && LEGAL_ITEMS_REQUIRED_TYPES.has(item.type)) {
          acc.push({ url: item.link, text: item.description });
        }

        return acc;
      }, []);
    });

    return true;
  }

  destroy() {
    // nothing
  }
}
