import cn from 'classnames';
import * as React from 'react';

import { MobileAppIconProps } from '../types';

import s from './AppStoreIcon.module.scss';

const AppStoreIcon: React.FC<MobileAppIconProps> = ({ className }) => {
  return (
    <svg
      className={cn(s.appStoreIcon, className)}
      width="110"
      height="30"
      viewBox="0 0 110 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3557 15.7374C16.3676 14.8347 16.6126 13.9496 17.0681 13.1645C17.5235 12.3793 18.1745 11.7197 18.9605 11.2469C18.4611 10.5491 17.8024 9.97488 17.0366 9.5698C16.2708 9.16471 15.4191 8.93992 14.5489 8.91329C12.6929 8.72266 10.8935 10 9.94763 10C8.9835 10 7.52724 8.93221 5.9591 8.96378C4.94478 8.99585 3.95626 9.28445 3.08982 9.80149C2.22339 10.3185 1.50861 11.0463 1.01512 11.914C-1.12256 15.5354 0.471941 20.8576 2.51966 23.7849C3.54418 25.2183 4.74155 26.8194 6.30829 26.7626C7.84143 26.7004 8.41402 25.8061 10.2647 25.8061C12.0981 25.8061 12.6353 26.7626 14.2338 26.7265C15.879 26.7004 16.9155 25.2868 17.9041 23.8398C18.6402 22.8185 19.2066 21.6897 19.5824 20.4952C18.6266 20.0997 17.811 19.4376 17.2372 18.5915C16.6634 17.7454 16.3568 16.7528 16.3557 15.7374Z"
        fill="currentColor"
      />
      <path
        d="M13.332 6.98767C14.229 5.93405 14.6709 4.57979 14.5639 3.2125C13.1935 3.35333 11.9277 3.9942 11.0185 5.0074C10.574 5.50237 10.2336 6.07821 10.0167 6.702C9.79979 7.32579 9.71065 7.98529 9.75438 8.64281C10.4398 8.64972 11.1179 8.50435 11.7376 8.21765C12.3574 7.93095 12.9025 7.5104 13.332 6.98767Z"
        fill="currentColor"
      />
      <path
        d="M35.7432 23.1338H30.5113L29.2549 26.7638H27.0388L31.9944 13.3335H34.2968L39.2524 26.7638H36.9986L35.7432 23.1338ZM31.0532 21.4587H35.2003L33.1559 15.5673H33.0987L31.0532 21.4587Z"
        fill="currentColor"
      />
      <path
        d="M49.957 21.8654C49.957 24.9082 48.2926 26.8632 45.7808 26.8632C45.1445 26.8957 44.5117 26.7523 43.9549 26.4494C43.398 26.1464 42.9396 25.6961 42.6322 25.1501H42.5846V30H40.5305V16.9689H42.5188V18.5976H42.5566C42.8782 18.0541 43.3443 17.6062 43.9054 17.3014C44.4665 16.9965 45.1016 16.8463 45.743 16.8665C48.2828 16.8665 49.957 18.831 49.957 21.8654ZM47.8457 21.8654C47.8457 19.8829 46.7987 18.5796 45.2011 18.5796C43.6317 18.5796 42.576 19.9104 42.576 21.8654C42.576 23.8383 43.6317 25.1596 45.2011 25.1596C46.7987 25.1596 47.8457 23.8658 47.8457 21.8654Z"
        fill="currentColor"
      />
      <path
        d="M60.9699 21.8654C60.9699 24.9082 59.3054 26.8632 56.7936 26.8632C56.1574 26.8957 55.5246 26.7523 54.9677 26.4493C54.4109 26.1464 53.9525 25.6961 53.645 25.15H53.5975V30H51.5433V16.9689H53.5316V18.5976H53.5694C53.891 18.0541 54.3571 17.6062 54.9182 17.3014C55.4793 16.9965 56.1144 16.8463 56.7558 16.8665C59.2957 16.8665 60.9699 18.831 60.9699 21.8654ZM58.8585 21.8654C58.8585 19.8829 57.8115 18.5796 56.214 18.5796C54.6445 18.5796 53.5888 19.9104 53.5888 21.8654C53.5888 23.8383 54.6445 25.1596 56.214 25.1596C57.8115 25.1596 58.8585 23.8658 58.8585 21.8654Z"
        fill="currentColor"
      />
      <path
        d="M68.2502 23.0223C68.4024 24.3541 69.7247 25.2286 71.5316 25.2286C73.263 25.2286 74.5086 24.3541 74.5086 23.1532C74.5086 22.1108 73.7574 21.4866 71.9785 21.0588L70.1996 20.6395C67.6792 20.0438 66.5091 18.8905 66.5091 17.019C66.5091 14.7017 68.573 13.1101 71.5036 13.1101C74.4039 13.1101 76.3922 14.7017 76.4592 17.019H74.3856C74.2615 15.6787 73.1291 14.8697 71.4744 14.8697C69.8197 14.8697 68.6874 15.6882 68.6874 16.8796C68.6874 17.8291 69.4106 18.3878 71.1797 18.8155L72.692 19.1788C75.5081 19.8305 76.6783 20.9374 76.6783 22.9018C76.6783 25.4144 74.6327 26.9881 71.3794 26.9881C68.3354 26.9881 66.2803 25.4514 66.1475 23.0222L68.2502 23.0223Z"
        fill="currentColor"
      />
      <path
        d="M81.1122 14.6522V16.9695H83.0152V18.5611H81.1122V23.9592C81.1122 24.7978 81.4933 25.1886 82.3298 25.1886C82.5558 25.1847 82.7813 25.1692 83.0056 25.1421V26.7242C82.6295 26.793 82.2472 26.8241 81.8646 26.8172C79.8386 26.8172 79.0484 26.0726 79.0484 24.1736V18.5611H77.5934V16.9695H79.0484V14.6522H81.1122Z"
        fill="currentColor"
      />
      <path
        d="M84.1115 21.8705C84.1115 18.7897 85.9659 16.8537 88.8577 16.8537C91.7591 16.8537 93.6049 18.7896 93.6049 21.8705C93.6049 24.9598 91.7688 26.8873 88.8577 26.8873C85.9475 26.8873 84.1115 24.9598 84.1115 21.8705ZM91.5119 21.8705C91.5119 19.7571 90.5221 18.5098 88.8576 18.5098C87.1932 18.5098 86.2045 19.7666 86.2045 21.8705C86.2045 23.9924 87.1932 25.2302 88.8576 25.2302C90.5221 25.2302 91.5119 23.9924 91.5119 21.8705Z"
        fill="currentColor"
      />
      <path
        d="M95.3035 16.9725H97.2626V18.6391H97.3102C97.4427 18.1186 97.7544 17.6586 98.1928 17.3365C98.6311 17.0143 99.1694 16.8496 99.7173 16.8701C99.954 16.8692 100.19 16.8944 100.421 16.9451V18.825C100.122 18.7357 99.8105 18.6947 99.4981 18.7036C99.1997 18.6917 98.9022 18.7432 98.626 18.8545C98.3499 18.9658 98.1016 19.1342 97.8982 19.3482C97.6949 19.5622 97.5413 19.8168 97.448 20.0944C97.3547 20.372 97.3238 20.666 97.3576 20.9564V26.7643H95.3035V16.9725Z"
        fill="currentColor"
      />
      <path
        d="M109.886 23.8899C109.609 25.6675 107.84 26.8873 105.577 26.8873C102.665 26.8873 100.858 24.9788 100.858 21.917C100.858 18.8456 102.675 16.8537 105.49 16.8537C108.259 16.8537 110 18.7147 110 21.6836V22.3722H102.932V22.4937C102.899 22.854 102.946 23.2171 103.067 23.5586C103.189 23.9002 103.384 24.2126 103.638 24.4749C103.893 24.7372 104.202 24.9435 104.544 25.0801C104.886 25.2167 105.255 25.2805 105.624 25.2672C106.109 25.3117 106.597 25.2017 107.013 24.9535C107.429 24.7054 107.753 24.3324 107.935 23.8899L109.886 23.8899ZM102.942 20.9675H107.945C107.963 20.6435 107.913 20.3192 107.797 20.0151C107.682 19.711 107.503 19.4335 107.272 19.2002C107.042 18.9668 106.765 18.7826 106.458 18.659C106.151 18.5355 105.822 18.4754 105.49 18.4823C105.156 18.4804 104.824 18.5433 104.515 18.6674C104.205 18.7914 103.924 18.9743 103.687 19.2053C103.45 19.4364 103.262 19.7111 103.134 20.0135C103.006 20.316 102.941 20.6402 102.942 20.9675Z"
        fill="currentColor"
      />
      <path
        d="M28.8574 5.4657L29.166 4.82544C29.4364 5.03309 29.7333 5.18883 30.0566 5.29265C30.3858 5.39648 30.712 5.45128 31.0354 5.45705C31.3587 5.45705 31.6555 5.41378 31.926 5.32726C32.2023 5.24074 32.4227 5.11096 32.5873 4.93792C32.7519 4.76487 32.8342 4.55145 32.8342 4.29766C32.8342 3.98618 32.7049 3.74968 32.4462 3.58818C32.1934 3.42667 31.8407 3.34592 31.3881 3.34592H30.0125V2.64509H31.3263C31.7202 2.64509 32.0259 2.56722 32.2434 2.41148C32.4668 2.24998 32.5785 2.0279 32.5785 1.74527C32.5785 1.53185 32.5109 1.35303 32.3757 1.20883C32.2463 1.05886 32.067 0.946382 31.8378 0.871397C31.6144 0.796411 31.3587 0.758919 31.0706 0.758919C30.7885 0.75315 30.4975 0.790643 30.1977 0.871397C29.8979 0.946382 29.6098 1.06751 29.3335 1.23479L29.0514 0.52531C29.4276 0.317658 29.8185 0.173455 30.2241 0.0927018C30.6356 0.00618016 31.0324 -0.0197763 31.4145 0.0148322C31.7966 0.0436728 32.1405 0.130194 32.4462 0.274397C32.7578 0.412832 33.0047 0.597412 33.1869 0.828136C33.375 1.05309 33.4691 1.32419 33.4691 1.64144C33.4691 1.92985 33.3926 2.18653 33.2398 2.41148C33.087 2.63067 32.8753 2.80083 32.6049 2.92196C32.3345 3.03732 32.02 3.095 31.6614 3.095L31.6967 2.8614C32.1082 2.8614 32.4638 2.92773 32.7636 3.0604C33.0693 3.18729 33.3045 3.36899 33.4691 3.60548C33.6396 3.84197 33.7248 4.11596 33.7248 4.42744C33.7248 4.72161 33.6484 4.98406 33.4955 5.21479C33.3427 5.43974 33.134 5.63009 32.8695 5.78583C32.6108 5.9358 32.3139 6.04539 31.9789 6.11461C31.6497 6.18383 31.3028 6.2069 30.9384 6.18383C30.5739 6.16076 30.2124 6.09154 29.8538 5.97618C29.5011 5.86081 29.1689 5.69065 28.8574 5.4657Z"
        fill="currentColor"
      />
      <path
        d="M37.9185 6.12326V5.15422L37.8744 4.97252V3.31996C37.8744 2.96811 37.7686 2.697 37.557 2.50666C37.3512 2.31054 37.0397 2.21248 36.6223 2.21248C36.346 2.21248 36.0756 2.25863 35.8111 2.35092C35.5466 2.43744 35.3232 2.55569 35.1409 2.70566L34.7882 2.0827C35.0292 1.89235 35.3173 1.74815 35.6524 1.65009C35.9933 1.54627 36.349 1.49435 36.7193 1.49435C37.3601 1.49435 37.8539 1.64721 38.2007 1.95292C38.5475 2.25863 38.721 2.72584 38.721 3.35457V6.12326H37.9185ZM36.3842 6.17518C36.0374 6.17518 35.7317 6.11749 35.4672 6.00213C35.2085 5.88677 35.0087 5.72815 34.8676 5.52626C34.7265 5.31861 34.656 5.085 34.656 4.82544C34.656 4.57741 34.7147 4.35245 34.8323 4.15057C34.9558 3.94868 35.1527 3.78718 35.4231 3.66605C35.6994 3.54492 36.0697 3.48435 36.5341 3.48435H38.0155V4.08135H36.5694C36.1462 4.08135 35.8611 4.15057 35.7141 4.289C35.5671 4.42744 35.4936 4.59471 35.4936 4.79083C35.4936 5.01579 35.5848 5.19748 35.767 5.33592C35.9492 5.46858 36.202 5.53492 36.5253 5.53492C36.8428 5.53492 37.1191 5.4657 37.3542 5.32726C37.5952 5.18883 37.7686 4.98694 37.8744 4.72161L38.042 5.29265C37.9303 5.56376 37.7334 5.78006 37.4512 5.94157C37.169 6.09731 36.8134 6.17518 36.3842 6.17518Z"
        fill="currentColor"
      />
      <path d="M40.2452 6.12326V1.53761H43.6842V2.2644H40.889L41.0918 2.07405V6.12326H40.2452Z" fill="currentColor" />
      <path
        d="M47.0286 6.17518C46.6406 6.17518 46.285 6.08865 45.9617 5.91561C45.6442 5.7368 45.3885 5.47435 45.1945 5.12826C45.0064 4.78218 44.9123 4.34957 44.9123 3.83044C44.9123 3.31131 45.0035 2.8787 45.1857 2.53261C45.3738 2.18653 45.6266 1.92696 45.944 1.75392C46.2673 1.58087 46.6289 1.49435 47.0286 1.49435C47.4871 1.49435 47.8927 1.59241 48.2455 1.78853C48.5982 1.98464 48.8774 2.25863 49.0831 2.61048C49.2889 2.95657 49.3918 3.36322 49.3918 3.83044C49.3918 4.29766 49.2889 4.70719 49.0831 5.05905C48.8774 5.4109 48.5982 5.68489 48.2455 5.881C47.8927 6.07712 47.4871 6.17518 47.0286 6.17518ZM44.5508 7.80178V1.53761H45.362V2.77487L45.3091 3.83909L45.3973 4.90331V7.80178H44.5508ZM46.9581 5.44839C47.2579 5.44839 47.5254 5.38206 47.7605 5.24939C48.0015 5.11673 48.1896 4.92926 48.3248 4.687C48.4659 4.43897 48.5364 4.15345 48.5364 3.83044C48.5364 3.50166 48.4659 3.21902 48.3248 2.98253C48.1896 2.74026 48.0015 2.5528 47.7605 2.42013C47.5254 2.28747 47.2579 2.22113 46.9581 2.22113C46.6641 2.22113 46.3967 2.28747 46.1556 2.42013C45.9205 2.5528 45.7324 2.74026 45.5913 2.98253C45.4561 3.21902 45.3885 3.50166 45.3885 3.83044C45.3885 4.15345 45.4561 4.43897 45.5913 4.687C45.7324 4.92926 45.9205 5.11673 46.1556 5.24939C46.3967 5.38206 46.6641 5.44839 46.9581 5.44839Z"
        fill="currentColor"
      />
      <path
        d="M50.7306 7.8537C50.5072 7.8537 50.2897 7.8162 50.078 7.74122C49.8664 7.672 49.6842 7.56818 49.5313 7.42974L49.8929 6.80678C50.0104 6.91638 50.1398 7.00002 50.2808 7.0577C50.4219 7.11538 50.5718 7.14422 50.7306 7.14422C50.9363 7.14422 51.1068 7.09231 51.242 6.98848C51.3772 6.88465 51.5036 6.70007 51.6212 6.43474L51.9121 5.80313L52.0003 5.69931L53.8344 1.53761H54.6633L52.3971 6.58183C52.2619 6.90484 52.1091 7.15864 51.9386 7.34322C51.774 7.5278 51.5917 7.65758 51.3919 7.73257C51.192 7.81332 50.9716 7.8537 50.7306 7.8537ZM51.8416 6.27035L49.7077 1.53761H50.5895L52.4059 5.62144L51.8416 6.27035Z"
        fill="currentColor"
      />
      <path
        d="M56.7848 6.18383C57.1963 6.18383 57.5607 6.12615 57.8782 6.01078C58.2015 5.88965 58.4573 5.72526 58.6454 5.51761C58.8335 5.30419 58.9275 5.05905 58.9275 4.78218C58.9275 4.43609 58.7776 4.1621 58.4778 3.96022C58.178 3.75834 57.7665 3.65739 57.2433 3.65739L57.2081 3.85639C57.5137 3.85639 57.7841 3.80737 58.0193 3.70931C58.2544 3.61125 58.4425 3.47858 58.5836 3.31131C58.7247 3.13826 58.7952 2.94215 58.7952 2.72296C58.7952 2.4634 58.7012 2.23844 58.5131 2.04809C58.3308 1.85774 58.081 1.71354 57.7636 1.61548C57.4461 1.51166 57.0846 1.46551 56.679 1.47705C56.4086 1.48282 56.1352 1.52319 55.8589 1.59818C55.5885 1.6674 55.3122 1.76834 55.0301 1.901L55.2593 2.54992C55.5121 2.42879 55.759 2.33938 56 2.2817C56.2469 2.22402 56.4879 2.19229 56.7231 2.18653C56.9641 2.18653 57.1728 2.21248 57.3491 2.2644C57.5314 2.31631 57.6725 2.39129 57.7724 2.48935C57.8723 2.58741 57.9223 2.70566 57.9223 2.84409C57.9223 2.97099 57.8782 3.08347 57.79 3.18153C57.7077 3.27382 57.5901 3.34303 57.4373 3.38918C57.2845 3.43532 57.1052 3.45839 56.8994 3.45839H56.1058V4.0554H56.9523C57.2992 4.0554 57.5696 4.11308 57.7636 4.22844C57.9576 4.3438 58.0546 4.50819 58.0546 4.72161C58.0546 4.87158 58.0017 5.00425 57.8958 5.11961C57.79 5.22921 57.6372 5.31573 57.4373 5.37918C57.2433 5.44263 57.0111 5.47435 56.7407 5.47435C56.4468 5.47435 56.1558 5.43686 55.8678 5.36187C55.5797 5.28112 55.324 5.17152 55.1006 5.03309L54.8449 5.6647C55.1094 5.83197 55.4092 5.96176 55.7443 6.05405C56.0853 6.14057 56.4321 6.18383 56.7848 6.18383Z"
        fill="currentColor"
      />
      <path
        d="M60.2144 6.12326V1.53761H61.0609V4.87735L63.9267 1.53761H64.6938V6.12326H63.8474V2.78353L60.9904 6.12326H60.2144Z"
        fill="currentColor"
      />
      <path
        d="M67.2628 6.12326V2.04809L67.4744 2.2644H65.5521V1.53761H69.82V2.2644H67.9065L68.1093 2.04809V6.12326H67.2628Z"
        fill="currentColor"
      />
      <path
        d="M72.8459 3.095C73.4397 3.10077 73.8923 3.23344 74.2038 3.493C74.5154 3.75257 74.6712 4.11884 74.6712 4.59183C74.6712 5.08789 74.5007 5.47147 74.1597 5.74257C73.8188 6.0079 73.3367 6.13768 72.7136 6.13192L70.6855 6.12326V1.53761H71.532V3.08635L72.8459 3.095ZM72.6431 5.50031C73.0252 5.50607 73.3132 5.43109 73.5072 5.27535C73.7071 5.11961 73.807 4.88889 73.807 4.58318C73.807 4.28324 73.71 4.06405 73.516 3.92561C73.322 3.78718 73.0311 3.71508 72.6431 3.70931L71.532 3.692V5.49165L72.6431 5.50031Z"
        fill="currentColor"
      />
      <path
        d="M78.1256 6.12326V1.53761H80.2947C80.8414 1.53761 81.2705 1.64144 81.5821 1.84909C81.8995 2.05098 82.0583 2.33938 82.0583 2.71431C82.0583 3.08924 81.9083 3.38053 81.6085 3.58818C81.3146 3.79006 80.9237 3.891 80.4358 3.891L80.5681 3.66605C81.1265 3.66605 81.541 3.76699 81.8114 3.96887C82.0818 4.17076 82.217 4.46781 82.217 4.86005C82.217 5.25805 82.0641 5.56952 81.7585 5.79448C81.4587 6.01367 80.9972 6.12326 80.3741 6.12326H78.1256ZM78.9544 5.483H80.3123C80.6651 5.483 80.9296 5.43109 81.1059 5.32726C81.2823 5.21767 81.3705 5.04463 81.3705 4.80813C81.3705 4.56587 81.2882 4.38995 81.1236 4.28035C80.9649 4.16499 80.7121 4.10731 80.3653 4.10731H78.9544V5.483ZM78.9544 3.51031H80.2242C80.5475 3.51031 80.7915 3.45263 80.956 3.33726C81.1265 3.21613 81.2118 3.04886 81.2118 2.83544C81.2118 2.61625 81.1265 2.45186 80.956 2.34227C80.7915 2.23267 80.5475 2.17787 80.2242 2.17787H78.9544V3.51031Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default React.memo(AppStoreIcon);
