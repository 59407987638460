import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { usePortalContainer } from './context';

const Portal: React.FC<React.PropsWithChildren> = ({ children }) => {
  const portalContainer = usePortalContainer();

  if (!portalContainer || !children) {
    return null;
  }

  return ReactDOM.createPortal(children, portalContainer);
};

export default Portal;
