import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { NotificationBar } from 'components/ui/NotificationBar';
import { snackBarStore } from 'stores/root';

import SnackBarItem from './SnackBarItem';

import s from './SnackBar.module.scss';

const SnackBar: React.FC = () => {
  const { items, closeItem } = snackBarStore;

  return <NotificationBar className={s.root} items={items} ComponentItem={SnackBarItem} onClose={closeItem} />;
};

export default observer(SnackBar);
