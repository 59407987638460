import * as React from 'react';

export const SCROLL_BLOCKED_ATTR_NAME = 'data-scroll';
const CSS_VAR_NAME = '--scrollbar-offset';

export const lockScroll = () => {
  const body = document.body;
  const position = window.scrollY;

  if (body.hasAttribute(SCROLL_BLOCKED_ATTR_NAME)) {
    return;
  }

  const scrollBarWidth = window.innerWidth - body.clientWidth;

  body.setAttribute(SCROLL_BLOCKED_ATTR_NAME, String(position));
  body.style.overflow = 'hidden';
  body.style.width = '100%';
  body.style.position = 'fixed';
  body.style.top = `-${position}px`;
  body.style.setProperty(CSS_VAR_NAME, `${scrollBarWidth}px`);
};

export const unlockScroll = (nextTop?: number) => {
  const body = document.body;

  if (!body.hasAttribute(SCROLL_BLOCKED_ATTR_NAME)) {
    return;
  }

  const position = nextTop || Number(body.getAttribute(SCROLL_BLOCKED_ATTR_NAME));

  body.removeAttribute(SCROLL_BLOCKED_ATTR_NAME);
  body.style.removeProperty('overflow');
  body.style.removeProperty('width');
  body.style.removeProperty('top');
  body.style.removeProperty('position');
  body.style.removeProperty(CSS_VAR_NAME);
  window.scrollTo(0, position);
};

export const useLockScrollBody = (lock: boolean) => {
  React.useEffect(() => {
    if (lock) {
      lockScroll();
    }

    return () => {
      lock && unlockScroll();
    };
  }, [lock]);
};
