import cn from 'classnames';
import * as React from 'react';

import { SocialIconProps } from '../types';

import s from './YappiIcon.module.scss';

export const YappiIcon: React.FC<SocialIconProps> = React.memo(({ view, className }) => {
  return (
    <svg
      className={cn(s.yappiIcon, s[`yappi-icon_${view}`], className)}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className={s.yappiIcon__background} cx="16" cy="16" r="16" fill="currentColor" />
      <path
        fill="currentColor"
        d="M25 10.6899L20.3949 15.3326C19.2292 16.5061 17.6498 17.1662 16.0024 17.1684C14.355 17.1705 12.7739 16.5146 11.6051 15.3442L7 10.7015L9.6623 8L15.9942 14.3576L22.3262 8L25 10.6899Z"
      />
      <path
        fill="currentColor"
        d="M15.2258 17.8948H16.7857C17.0797 17.8963 17.3611 18.0149 17.5686 18.2247C17.7762 18.4345 17.8931 18.7186 17.8938 19.0149V24H14.1119V19.0149C14.1119 18.8675 14.1408 18.7217 14.1968 18.5856C14.2528 18.4495 14.3349 18.3259 14.4383 18.2218C14.5418 18.1178 14.6646 18.0354 14.7997 17.9792C14.9349 17.9231 15.0796 17.8944 15.2258 17.8948Z"
      />
    </svg>
  );
});
