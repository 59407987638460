import { action, makeObservable, observable, runInAction } from 'mobx';

import { ENDPOINTS } from 'configs/endpoints';
import { ILocalStore } from 'stores/interfaces/ILocalStore';
import { UserModel } from 'stores/models/user';
import { userStore } from 'stores/root';
import { MetaStore } from 'stores/shared/MetaStore';
import call from 'utils/api';

import { ApiAuthResponse } from './types';

export class AuthPageStore implements ILocalStore {
  oauthUrl: string | null = null;

  readonly meta = new MetaStore();

  constructor() {
    makeObservable(this, {
      oauthUrl: observable,
      fetchOAuthUrl: action.bound,
    });

    this.fetchOAuthUrl();
  }

  async fetchOAuthUrl(): Promise<void> {
    this.meta.setLoadedStartMeta();

    const response = await call<ApiAuthResponse>({ endpoint: ENDPOINTS.user.auth });

    if (response.isError) {
      this.meta.setLoadedErrorMeta();

      return;
    }

    if (response.data.user) {
      userStore.setUser(UserModel.normalizeApi(response.data.user));
    }

    runInAction(() => {
      this.oauthUrl = response.data.url;
      this.meta.setLoadedSuccessMeta();
    });
  }

  destroy() {
    this.meta.destroy();
  }
}
