import * as React from 'react';

import Alert from 'components/ui/Alert';
import BaseClickable, { ClickedProps } from 'components/ui/BaseClickable';
import Typo from 'components/ui/Typo';
import { ROUTES } from 'configs/routes';

import s from './ErrorStub.module.scss';

type ErrorStubProps = {
  /** Со страницы авторизации передаем функцию-редирект в oauth, если функция не передана – редиректимся на AuthPage */
  redirect?: VoidFunction;
};

const ErrorStub: React.FC<ErrorStubProps> = ({ redirect }) => {
  const buttonProps: ClickedProps<never> = redirect ? { onClick: redirect } : { element: 'link', to: ROUTES.auth };

  return (
    <div className={s.root}>
      <Alert view="error">
        Произошла ошибка при попытке авторизации. Попробуйте{' '}
        <BaseClickable {...buttonProps} withHover>
          <Typo color="blue-main-100" weight="bold" view="body-3">
            войти
          </Typo>
        </BaseClickable>{' '}
        ещё раз.
      </Alert>
    </div>
  );
};

export default React.memo(ErrorStub);
