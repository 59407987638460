import { observer } from 'mobx-react-lite';
import * as React from 'react';

import LoadingStub from 'components/ui/LoadingStub';
import AuthPage from 'pages/auth/AuthPage';
import { rootStore } from 'stores/root';
import { AnyObject } from 'types/UtilityTypes';

/* HOC для страниц, для которых нужна авторизация */
export const withAuth = <P extends AnyObject>(Component: React.ComponentType<P>) =>
  observer((props: P) => {
    const {
      user: { fetchUser, isAuthorized, isAuthorizing },
    } = rootStore;

    React.useEffect(() => {
      if (!isAuthorized) {
        fetchUser();
      }
    }, [isAuthorized, fetchUser]);

    if (isAuthorizing) {
      return <LoadingStub />;
    }

    if (!isAuthorized) {
      return <AuthPage />;
    }

    return <Component {...props} />;
  });
