import { action, makeObservable, observable } from 'mobx';

import { ILocalStore } from 'stores/interfaces/ILocalStore';

export class OpenStore implements ILocalStore {
  isOpen: boolean;

  constructor(defaultValue = false) {
    this.isOpen = defaultValue;

    makeObservable(this, {
      isOpen: observable,
      open: action.bound,
      close: action.bound,
      toggle: action.bound,
    });
  }

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  destroy() {
    // nothing to do
  }
}
