import cn from 'classnames';
import * as React from 'react';

import { SocialIconProps } from '../types';

import s from './TenchatIcon.module.scss';

export const TenchatIcon: React.FC<SocialIconProps> = React.memo(({ view, className }) => {
  return (
    <svg
      className={cn(s.tenchatIcon, s[`tenchat-icon_${view}`], className)}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className={s.tenchatIcon__background} cx="16" cy="16" r="16" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M24 8.9412C24 8.4214 23.5786 8 23.0588 8H8.94118C8.42139 8 8 8.4214 8 8.9412V12.2354C8 12.7552 8.42139 13.1766 8.94118 13.1766H12.2383C12.7581 13.1766 13.1795 13.5979 13.1795 14.1177V23.0588C13.1795 23.5786 13.6009 24 14.1207 24H17.8854C18.4052 24 18.8266 23.5786 18.8266 23.0588V14.1177C18.8266 13.5979 19.2479 13.1766 19.7677 13.1766H23.0588C23.5786 13.1766 24 12.7552 24 12.2354V8.9412Z"
      />
    </svg>
  );
});
