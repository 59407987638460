import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import BaseClickable from 'components/ui/BaseClickable';
import ButtonPrimary from 'components/ui/Button/ButtonPrimary';
import ButtonText from 'components/ui/Button/ButtonText';
import Loader from 'components/ui/Loader';
import Picture from 'components/ui/Picture';
import Typo from 'components/ui/Typo';
import headerUrls from 'configs/headerUrls';
import { ROUTES } from 'configs/routes';
import { userStore } from 'stores/root';

import s from './User.module.scss';

type UserProps = {
  className?: string;
};

const User: React.FC<UserProps> = ({ className }) => {
  const { isAuthorizing, user } = userStore;

  if (isAuthorizing) {
    return <Loader className={className} />;
  }

  if (!user) {
    return (
      <>
        <ButtonPrimary className={cn('desktop-only', className)} size="s" element="link" to={ROUTES.auth}>
          Личный кабинет
        </ButtonPrimary>
        <ButtonText className={cn('mobile-only', 'tablet-only', className)} element="link" to={ROUTES.auth}>
          Войти
        </ButtonText>
      </>
    );
  }

  return (
    <BaseClickable className={cn(s.user, className)} element="a" href={headerUrls.user}>
      {user.avatarUrl ? (
        <Picture className={s.user__avatar} src={user.avatarUrl} alt="avatar" width={40} height={40} />
      ) : (
        <Typo view="h6" weight="regular" className={s.user__avatar}>
          {user.firstName[0].toUpperCase()}
        </Typo>
      )}
      <Typo className="desktop-only" view="h4" weight="regular" color="dark-purple-100" textAlign="center">
        {user.fullName}
      </Typo>
    </BaseClickable>
  );
};

export default observer(User);
