import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Alert from 'components/ui/Alert';
import Loader from 'components/ui/Loader';
import { RSV_FRONT_URL } from 'configs/env';
import { userStore } from 'stores/root';

const LogoutRedirectPage: React.FC = () => {
  const { logout, logoutMeta } = userStore;

  React.useLayoutEffect(() => {
    const action = async () => {
      await logout();
      window.location.href = RSV_FRONT_URL;
    };

    action();
  }, [logout]);

  return logoutMeta.isError ? (
    <Alert view="error">Произошла ошибка. Попробуйте ещё раз</Alert>
  ) : (
    <Loader mode="absolute" />
  );
};

export default observer(LogoutRedirectPage);
