import { IUser, ApiUser, UserRole } from './types';

export class UserModel implements IUser {
  readonly id;
  readonly firstName;
  readonly lastName;
  readonly avatarUrl;
  readonly email;
  readonly role;
  readonly isStaff;
  readonly isActive;

  constructor(initialData: IUser) {
    this.id = initialData.id;
    this.firstName = initialData.firstName;
    this.lastName = initialData.lastName;
    this.avatarUrl = initialData.avatarUrl;
    this.email = initialData.email;
    this.role = initialData.role;
    this.isStaff = initialData.isStaff;
    this.isActive = initialData.isActive;
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get isAdmin(): boolean {
    return this.role === UserRole.admin || this.role === UserRole.adminLimit;
  }

  static normalizeApi(from: ApiUser): IUser {
    return {
      email: from.email,
      firstName: from.first_name,
      lastName: from.last_name,
      avatarUrl: from.avatar_url,
      role: from.role,
      id: from.id,
      isStaff: from.is_staff,
      isActive: from.is_active,
    };
  }

  static createFromApi(from: ApiUser): UserModel {
    return new UserModel(UserModel.normalizeApi(from));
  }
}
