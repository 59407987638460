import cn from 'classnames';
import * as React from 'react';

import { SocialIconProps } from '../types';

import s from './TelegramIcon.module.scss';

export const TelegramIcon: React.FC<SocialIconProps> = React.memo(({ view, className }) => {
  return (
    <svg
      className={cn(s.telegramIcon, s[`telegram-icon_${view}`], className)}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className={s.telegramIcon__background} cx="16" cy="16" r="16" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M7.11 16.4647C7.51 16.5947 8.46 16.8847 9.22 17.1247C9.97 17.3647 10.62 17.5347 10.66 17.5147C10.7 17.4947 12.8 16.1647 15.34 14.5747C19.79 11.7747 20.3 11.4847 20.3 11.7447C20.3 11.8047 18.64 13.3447 16.61 15.1747C14.58 17.0047 12.9 18.5247 12.87 18.5547C12.84 18.5747 12.77 19.3947 12.7 20.3747C12.63 21.3547 12.57 22.2747 12.55 22.4247C12.52 22.6847 12.53 22.6947 12.73 22.6947C12.98 22.6947 13.27 22.4847 13.89 21.8547C14.15 21.5947 14.58 21.1747 14.84 20.9347L15.32 20.4947L17.34 21.9847C19.28 23.4147 19.62 23.6247 19.96 23.6347C20.18 23.6347 20.5 23.4247 20.62 23.1947C20.72 22.9947 23.36 10.7147 23.44 10.0847C23.49 9.68469 23.39 9.36469 23.14 9.15469C22.88 8.93469 22.51 8.94469 21.83 9.21469C21.25 9.44469 14.16 12.1747 9.63 13.9247C6.07 15.2947 6 15.3347 6 15.7447C6 16.0347 6.28 16.2147 7.11 16.4747V16.4647Z"
      />
    </svg>
  );
});
