import cn from 'classnames';
import * as React from 'react';

import BaseClickable, { ClickedProps, ValueProp } from 'components/ui/BaseClickable';
import Loader from 'components/ui/Loader';
import Typo from 'components/ui/Typo';

import { ButtonSize } from './config';

import s from './BaseButton.module.scss';

export type BaseButtonProps<V extends ValueProp> = {
  children?: React.ReactNode;
  before?: React.ReactNode;
  after?: React.ReactNode;
  className?: string;
  caps?: boolean;
  full?: boolean;
  size?: ButtonSize;
  isLoading?: boolean;
  disabled?: boolean;
} & ClickedProps<V>;

const BaseButton = <V extends ValueProp>(
  { size = 'm', children, before, after, className, caps, full, isLoading, disabled, ...props }: BaseButtonProps<V>,
  ref: React.ForwardedRef<HTMLElement>
): React.ReactElement => {
  return (
    <BaseClickable
      className={cn(
        className,
        s.button,
        s[`button_size-${size}`],
        disabled && s.button_disabled,
        full && s.button_full,
        isLoading && s.button_loading
      )}
      disabled={disabled}
      ref={ref}
      stopPropagation
      {...props}
    >
      {before}
      {children && (
        <Typo className={s['button__text']} element="span" view={caps ? 'button-caps' : 'button'}>
          {children}
        </Typo>
      )}
      {after}
      {isLoading && <Loader className={s['button__loader']} mode="absolute" />}
    </BaseClickable>
  );
};

export default React.forwardRef(BaseButton) as typeof BaseButton;
