import { QueryParams } from 'configs/qp';

import { ParamsMap } from './types';

export const getInitialMap = () => {
  const params = new URLSearchParams(window.location.search);

  return Object.values(QueryParams).reduce(
    (acc, key) => ({
      ...acc,
      [key]: params.get(key) || null,
    }),
    {} as ParamsMap
  );
};
