import cn from 'classnames';
import * as React from 'react';

import { ValueProp } from '../../BaseClickable';
import BaseButton, { BaseButtonProps } from '../BaseButton';

import s from './ButtonPrimary.module.scss';

const ButtonPrimary = <V extends ValueProp>(
  { className, disabled, ...props }: BaseButtonProps<V>,
  ref: React.ForwardedRef<any>
) => {
  return (
    <BaseButton
      {...props}
      className={cn(className, s['button-primary'], disabled && s['button-primary_disabled'])}
      disabled={disabled}
      // @ts-ignore
      ref={ref}
    />
  );
};

export default React.forwardRef(ButtonPrimary) as typeof ButtonPrimary;
