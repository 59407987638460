import * as React from 'react';

import { NotificationBarItemProps } from 'components/ui/NotificationBar';
import { Notification } from 'components/ui/NotificationBar/Notification';
import { SnackBarStoreItemType } from 'stores/global/SnackBarStore';

const AUTO_CLOSE_TIMEOUT = 5000;

const SnackBarItem: React.FC<NotificationBarItemProps> = ({ id, type, data, onClose }) => {
  React.useEffect(() => {
    if (!onClose) {
      return;
    }

    const timeout = setTimeout(() => {
      onClose(id);
    }, AUTO_CLOSE_TIMEOUT);

    return () => {
      clearTimeout(timeout);
    };
  }, [id, onClose]);

  if (type === SnackBarStoreItemType.success) {
    return <Notification color="success">{data.content}</Notification>;
  }

  if (type === SnackBarStoreItemType.error) {
    return <Notification color="error">{data.content}</Notification>;
  }

  return null;
};

export default React.memo(SnackBarItem);
