import * as React from 'react';

import Loader from 'components/ui/Loader';

import s from './Suspense.module.scss';

export type SuspenseProps = React.PropsWithChildren &
  (
    | {
        withLoader?: boolean;
        width?: never;
        height?: never;
      }
    | {
        withLoader?: never;
        width?: number;
        height?: number;
      }
  );

const Suspense: React.FC<SuspenseProps> = ({ children, withLoader, width, height }) => {
  const fallback = React.useMemo(() => {
    if (withLoader) {
      return <Loader className={s.loader} mode="absolute" />;
    }

    return <span className={s.stub} style={{ width, height }} />;
  }, [withLoader, width, height]);

  return <React.Suspense fallback={fallback}>{children}</React.Suspense>;
};

export default Suspense;
