import cn from 'classnames';
import * as React from 'react';

import s from './Container.module.scss';

export type ContainerProps = React.PropsWithChildren<{
  element?: React.ElementType;
  className?: string;
}>;

const Container: React.FC<ContainerProps> = ({ element: Element = 'div', className, children }) => {
  return <Element className={cn(className, s.container)}>{children}</Element>;
};

export default Container;
