import { RootStore } from './RootStore';

/**
 * Инстанс `RootStore`
 */
export const rootStore = new RootStore();

/**
 * Глобальный стор пользователя
 */
export const userStore = rootStore.user;

/**
 * Глобальный стор нотификаций
 */
export const snackBarStore = rootStore.snackBar;
