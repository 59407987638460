import cn from 'classnames';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import Portal from 'components/ui/Portal';
import Suspense from 'components/ui/Suspense';
import { useCloseOnEscape } from 'hooks/useCloseOnEscape';
import { lockScroll, unlockScroll } from 'hooks/useLockScrollBody';

import s from './Popup.module.scss';

const DEFAULT_CONTEXT = {};

export type PopupContentComponentProps<C = any> = {
  close: VoidFunction;
  context: C;
};

export type BasePopupProps<C = any> = {
  contentLoader: () => Promise<{ default: React.ComponentType<PopupContentComponentProps<C>> }>;
  open: boolean;
  withLockScroll?: boolean;
  onClose: VoidFunction;
  onAfterExit?: VoidFunction;
  context?: C;
  defaultContext?: C;
  withHeader?: boolean;
};

export type PopupProps<C = any> = BasePopupProps<C> & {
  classNameAnimation?: string;
  className?: string;
  classNameOverflow?: string;
  classNameContainer?: string;
};

const Popup = <C,>({
  classNameAnimation = 'animation-fade',
  open,
  onAfterExit,
  contentLoader,
  className,
  classNameOverflow,
  classNameContainer,
  onClose,
  context,
  defaultContext = DEFAULT_CONTEXT as C,
  withLockScroll = true,
  withHeader = true,
}: PopupProps<C>) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  const Content = React.useMemo(() => React.lazy(contentLoader), [contentLoader]);

  useCloseOnEscape({ open, onClose });

  React.useEffect(() => {
    if (open && withLockScroll) {
      lockScroll();
    } else if (!open && withLockScroll) {
      unlockScroll();
    }
  }, [open, withLockScroll]);

  // При переходах между страницами разблокируем скролл
  React.useEffect(
    () => () => {
      if (withLockScroll) {
        unlockScroll();
      }
    },
    [withLockScroll]
  );

  return (
    <Portal>
      <CSSTransition
        unmountOnExit
        mountOnEnter
        in={open}
        timeout={300}
        classNames={classNameAnimation}
        onExited={onAfterExit}
        nodeRef={ref}
      >
        <div ref={ref} className={cn(s.popup, className, withHeader && s['popup_with-header'])}>
          <div className={cn(s.popup__overflow, classNameOverflow)} onClick={onClose} />
          <div className={cn(s.popup__container, classNameContainer)}>
            <Suspense withLoader>
              <Content close={onClose} context={context || (defaultContext as C)} />
            </Suspense>
          </div>
        </div>
      </CSSTransition>
    </Portal>
  );
};

export default React.memo(Popup) as typeof Popup;
