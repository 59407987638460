import * as React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { QueryParams } from 'configs/qp';
import { rootStore } from 'stores/root';

/**
 * При первом заходе, считываем системные переменные из query-параметров
 * */
export const useInitQueryParams = (): void => {
  const { qpStore } = rootStore;
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    /** Записываем ключи query-параметров из конфига в стор */
    Object.values(QueryParams).forEach((item) => {
      const element = searchParams.get(item);

      if (!element) {
        return;
      }

      qpStore.setParam(item, element);
    });
    qpStore.setInitialized();
  }, [location, navigate, qpStore, searchParams]);
};

type SystemParamsStoreProps = {
  children: React.ReactNode;
};

const QpParamsStoreInitializer: React.FC<SystemParamsStoreProps> = ({ children }) => {
  useInitQueryParams();

  return <>{children}</>;
};

export default QpParamsStoreInitializer;
