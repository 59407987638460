import cn from 'classnames';
import * as React from 'react';

import Popup, { BasePopupProps } from 'components/ui/popups/Popup';

import { SidebarPopupSide } from './config';

import s from './SidebarPopup.module.scss';

export type SidebarPopupProps<C> = BasePopupProps<C> & {
  side?: SidebarPopupSide;
  view?: 'primary' | 'secondary';
  classNameContainer?: string;
};

const SidebarPopup = <C,>({
  side = SidebarPopupSide.right,
  view = 'primary',
  classNameContainer,
  ...props
}: SidebarPopupProps<C>) => {
  return (
    <Popup
      className={cn(s.sidebar, s[`sidebar_side_${side}`], s[`sidebar_view_${view}`])}
      classNameAnimation="animation-slide"
      classNameOverflow={s.sidebar__overflow}
      classNameContainer={cn(s.sidebar__container, classNameContainer)}
      {...props}
    />
  );
};

export default React.memo(SidebarPopup) as typeof SidebarPopup;
