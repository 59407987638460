import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useFooterStore } from 'stores/local/FooterStore';

import LegalItem from './LegalItem';

import s from './LegalList.module.scss';

const LegalList: React.FC = () => {
  const { legalItems } = useFooterStore();

  return (
    <ul className={s.legalList}>
      {legalItems.map((item) => (
        <li className={s.legalList__item} key={item.text}>
          <LegalItem url={item.url} text={item.text} />
        </li>
      ))}
    </ul>
  );
};

export default observer(LegalList);
