import cn from 'classnames';
import * as React from 'react';

import BaseClickable from 'components/ui/BaseClickable';
import { useRem } from 'hooks/useRem';

import { ChatIcon } from '../svgs/ChatIcon';
import { TelegramIcon } from '../svgs/TelegramIcon';

import s from './Buttons.module.scss';

type ButtonsProps = {
  isOpen: boolean;
  handleChatClick: VoidFunction;
};

const Buttons: React.FC<ButtonsProps> = ({ isOpen, handleChatClick }) => {
  const calcRem = useRem();

  return (
    <div className={cn(s.root, isOpen && s.root_opened)}>
      <BaseClickable onClick={handleChatClick} className={s.root__button} withHover>
        <ChatIcon size={calcRem({ desktop: 27 })} />
      </BaseClickable>
      <BaseClickable element="a" href="https://t.me/platform_rsv_bot" className={s.root__button} withHover>
        <TelegramIcon size={calcRem({ desktop: 27 })} />
      </BaseClickable>
    </div>
  );
};

export default React.memo(Buttons);
