import cn from 'classnames';
import * as React from 'react';

import { IconComponent } from 'types/IconComponent';
import { Color } from 'types/colors';
import { getColor } from 'utils/getColor';

import s from './IconWrapper.module.scss';

export type IconSizeProps =
  | {
      size?: string | number;
      width?: never;
      height?: never;
    }
  | {
      size?: never;
      width?: string | number;
      height?: string | number;
    };

export type IconBaseProps = Omit<React.ComponentProps<'svg'>, 'width' | 'height' | 'color'> & {
  className?: string;
  title?: string;
  color?: Color;
};

export type IconProps = IconBaseProps & IconSizeProps & React.AriaAttributes;

export const withIconWrapper = (Component: IconComponent) =>
  React.memo(({ className, size, width, height, color, ...props }: IconProps) => {
    const iconWidth = width || size;
    const iconHeight = height || size;

    return (
      <Component
        className={cn(className, s.icon)}
        style={{ width: iconWidth, height: iconHeight }}
        role="img"
        color={color ? getColor(color) : undefined}
        {...props}
      />
    );
  }) as React.FC<IconProps>;
