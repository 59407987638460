import cn from 'classnames';
import * as React from 'react';

import BaseClickable from 'components/ui/BaseClickable';

import { mobileApps } from './config';
import { MobileAppType } from './types';

import s from './AppLink.module.scss';

type AppLinkProps = {
  type: MobileAppType;
  className?: string;
};

const AppLink: React.FC<AppLinkProps> = ({ type, className }) => {
  const { url, Icon } = mobileApps[type];

  return (
    <BaseClickable className={cn(s.appLink, className)} element="a" href={url}>
      <Icon className={cn(s.appLinkIcon)} />
    </BaseClickable>
  );
};

export default React.memo(AppLink);
