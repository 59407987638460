import cn from 'classnames';
import * as React from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';

import { NotificationBarItemData } from 'types/notifications';

import s from './NotificationBar.module.scss';

export type NotificationBarItemProps = NotificationBarItemData & {
  onClose?: (id: string) => void;
};

export type NotificationBarProps = {
  items: NotificationBarItemData[];
  onClose: (id: string) => void;
  ComponentItem: React.ComponentType<NotificationBarItemProps>;
  className?: string;
};

const DURATION = 300;

type AnimationProps = {
  entered: {
    opacity: number;
    transform: string;
    transition: string;
  };
  exiting: {
    opacity: number;
    transform: string;
    transition: string;
  };
};

const startItemStyles = {
  transform: 'translateY(20px)',
  opacity: 0,
  transition: `transform ${DURATION}ms ease, opacity ${DURATION}ms ease`,
};

const NotificationBar: React.FC<NotificationBarProps> = ({ items, className, ComponentItem, onClose }) => {
  const refItems = React.useRef<(HTMLDivElement | null)[]>([]);
  const [styles, setStyles] = React.useState<AnimationProps[]>([]);
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    if (!items.length) {
      setHeight(0);

      return;
    }

    const arr: AnimationProps[] = [];
    let sum = 0;

    for (let i = items.length - 1; i >= 0; i -= 1) {
      if (refItems.current[i]) {
        arr.push({
          entered: {
            opacity: 1,
            transform: `translateY(-${sum}px)`,
            transition: `transform ${DURATION}ms ease, opacity ${DURATION}ms ease`,
          },
          exiting: {
            opacity: 0,
            transform: `translateY(-${sum}px) scale(0.8)`,
            transition: `transform ${DURATION / 2}ms ease, opacity ${DURATION / 2}ms ease`,
          },
        });
        sum += (refItems.current[i] as HTMLDivElement).clientHeight;
      }
    }
    setStyles(arr.reverse());
    setHeight(sum);
  }, [items]);

  const wrapperStyles = React.useMemo(() => ({ height, transition: `height ${DURATION}ms ease` }), [height]);

  return (
    <TransitionGroup className={cn(s.bar, className)} style={wrapperStyles}>
      {items.map((item, index) => (
        <Transition key={item.id} appear mountOnEnter unmountOnExit timeout={DURATION / 2}>
          {(_transitionState): React.ReactElement => {
            const transitionState = _transitionState as keyof AnimationProps;

            return (
              <div
                className={s.bar__item}
                ref={(el): void => {
                  refItems.current[index] = el;
                }}
                style={(styles[index] && styles[index][transitionState]) || startItemStyles}
              >
                <ComponentItem id={item.id} data={item.data} type={item.type} onClose={onClose} />
              </div>
            );
          }}
        </Transition>
      ))}
    </TransitionGroup>
  );
};

export default React.memo(NotificationBar);
