import { observer } from 'mobx-react-lite';
import * as React from 'react';

import RSVRedirect from 'pages/RSVRedirect';
import { rootStore } from 'stores/root';
import { AnyObject } from 'types/UtilityTypes';

/* HOC для страниц, на которых нужна роль админа */
export const withAdmin = <P extends AnyObject>(Component: React.ComponentType<P>) =>
  observer((props: P) => {
    const {
      user: { user },
    } = rootStore;

    if (!user || !user.isAdmin) {
      return <RSVRedirect />;
    }

    return <Component {...props} />;
  });
