import cn from 'classnames';
import * as React from 'react';

import { SocialIconProps } from '../types';

import s from './OkIcon.module.scss';

export const OkIcon: React.FC<SocialIconProps> = React.memo(({ view, className }) => {
  return (
    <svg
      className={cn(s.okIcon, s[`ok-icon_${view}`], className)}
      width="32"
      height="32"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className={s.okIcon__background} cx="100" cy="100" r="100" fill="currentColor" />
      <path
        fill="currentColor"
        d="M123.8,87.3c-6.1,6.1-14.5,9.9-23.8,9.9l0,0c-9.3,0-17.7-3.8-23.8-9.9s-9.9-14.5-9.9-23.8s3.8-17.7,9.9-23.8
        s14.5-9.9,23.8-9.9s17.7,3.8,23.8,9.9s9.9,14.5,9.9,23.8S129.9,81.2,123.8,87.3z M100,48.1c-4.2,0-8,1.7-10.8,4.5
        s-4.5,6.6-4.5,10.8s1.7,8,4.5,10.8s6.6,4.5,10.8,4.5c4.2,0,8-1.7,10.8-4.5s4.5-6.6,4.5-10.8s-1.7-8-4.5-10.8
        C108,49.9,104.2,48.1,100,48.1z M135.4,97.3l9.9,13.5l0,0c0.5,0.7,0.4,1.6-0.3,2.1c-8.3,6.8-18,11.3-28.1,13.7l19.1,36.8
        c0.5,1.1-0.2,2.3-1.4,2.3h-20.4c-0.6,0-1.2-0.4-1.5-1L100,134.3l-12.8,30.3c-0.2,0.6-0.8,1-1.5,1H65.3c-1.2,0-1.9-1.3-1.4-2.3
        L83,126.6c-10.1-2.4-19.7-6.9-28.1-13.7c-0.6-0.5-0.8-1.5-0.3-2.1l9.9-13.5c0.6-0.8,1.6-0.9,2.3-0.3c9.3,7.9,20.8,13.1,33.1,13.1
        S123.6,105,133,97C133.8,96.4,134.9,96.5,135.4,97.3z"
      />
    </svg>
  );
});
