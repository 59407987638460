import { action, makeObservable, observable } from 'mobx';

import { QueryParams } from 'configs/qp';
import { ILocalStore } from 'stores/interfaces/ILocalStore';

import { ParamsMap } from './types';
import { getInitialMap } from './utils';

/**
 * Стор для переменных из query-params
 */
export class QpParamsStore implements ILocalStore {
  paramsMap: ParamsMap = getInitialMap();
  initialized = false;

  constructor() {
    makeObservable<QpParamsStore>(this, {
      paramsMap: observable.ref,
      setParam: action.bound,
      setInitialized: action.bound,
      initialized: observable.ref,
    });
  }

  setInitialized(): void {
    this.initialized = true;
  }

  setParam(key: QueryParams, value: string | null): void {
    this.paramsMap = {
      ...this.paramsMap,
      [key]: value,
    };
  }

  destroy() {}
}
