import { action, makeObservable, observable } from 'mobx';

import { NotificationBarItemData } from 'types/notifications';

import { SnackBarStoreItemType } from './types';

/** Максимальное кол-во нотификаций в баре */
const MAX_COUNT = 3;

export class SnackBarStore {
  items: NotificationBarItemData[] = [];

  constructor() {
    makeObservable(this, {
      items: observable.ref,
      setItems: action.bound,
      addCustom: action.bound,
      addSuccess: action.bound,
      addError: action.bound,
      closeItem: action.bound,
    });
  }

  private _generateId(): string {
    return `${Date.now()}`;
  }

  closeItem(id: string): void {
    this.setItems(this.items.filter((item) => item.id !== id));
  }

  setItems(items: NotificationBarItemData[]): void {
    this.items = items;
  }

  addCustom(item: NotificationBarItemData): void {
    if (this.items.length + 1 > MAX_COUNT) {
      const d = this.items.length + 1 - MAX_COUNT;

      this.setItems([...this.items, item].splice(d, MAX_COUNT));
    } else {
      this.setItems([...this.items, item]);
    }
  }

  addSuccess(content: string): void {
    this.addCustom({
      id: this._generateId(),
      type: SnackBarStoreItemType.success,
      data: {
        content,
      },
    });
  }

  addError(content: string): void {
    this.addCustom({
      id: this._generateId(),
      type: SnackBarStoreItemType.error,
      data: {
        content,
      },
    });
  }
}

export const snackBar = new SnackBarStore();
