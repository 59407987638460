import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { Container } from 'components/ui/Container';
import SidebarPopup, { SidebarPopupSide } from 'components/ui/popups/SidebarPopup';
import { useOpenStore } from 'hooks/useOpenStore';

import Logo from './Logo';
import MenuButton from './MenuButton';
import SocialList from './SocialList';
import User from './User';

import s from './Header.module.scss';

const SidebarLoader = () => import('./SideMenu');

const Header: React.FC = () => {
  const { isOpen, open, close } = useOpenStore();

  return (
    <Container className={s.header} element="header">
      <MenuButton onClick={open} />
      <Logo className={s.header__logo} />
      <SocialList className={s['header__social-list']} />
      <User className={s.header__user} />
      <SidebarPopup
        withHeader={false}
        view="secondary"
        side={SidebarPopupSide.left}
        open={isOpen}
        onClose={close}
        contentLoader={SidebarLoader}
        classNameContainer={s['popup-container']}
      />
    </Container>
  );
};

export default observer(Header);
