import * as React from 'react';

const Context = React.createContext<HTMLDivElement | null>(null);

const PORTAL_CONTAINER_CLASSNAME = 'portal';

export const PortalContainerProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [container, setContainer] = React.useState<HTMLDivElement | null>(null);

  return (
    <>
      <Context.Provider value={container}>{children}</Context.Provider>
      <div ref={setContainer} className={PORTAL_CONTAINER_CLASSNAME} />
    </>
  );
};

export const usePortalContainer = () => React.useContext(Context);
