import cn from 'classnames';
import * as React from 'react';

import { SocialIconView } from 'components/ui/icons/socialIcons/types';

import SocialItem, { SocialNetworkType } from './SocialItem';

import s from './SocialList.module.scss';

type SocialListProps = {
  view?: SocialIconView;
  className?: string;
};

const SocialList: React.FC<SocialListProps> = ({ view = SocialIconView.primary, className }) => {
  return (
    <ul className={cn(s.socialList, s[`social-list_${view}`], className)}>
      {Object.values(SocialNetworkType).map((type) => (
        <li key={type}>
          <SocialItem type={type} view={view} />
        </li>
      ))}
    </ul>
  );
};

export default React.memo(SocialList);
