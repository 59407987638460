import cn from 'classnames';
import * as React from 'react';

import { Container } from 'components/ui/Container';
import { useLocalStore } from 'hooks/useLocalStore';
import { FooterStoreProvider, FooterStore } from 'stores/local/FooterStore';

import AppLinks from './AppLinks';
import Copyright from './Copyright';
import LegalList from './LegalList';

import s from './Footer.module.scss';

type FooterProps = {
  className?: string;
};

const Footer: React.FC<FooterProps> = ({ className }) => {
  const store = useLocalStore(() => new FooterStore());

  return (
    <FooterStoreProvider store={store}>
      <Container className={cn(s.footer, className)} element="footer">
        <Copyright />
        <LegalList />
        <AppLinks />
      </Container>
    </FooterStoreProvider>
  );
};

export default React.memo(Footer);
