export enum UserRole {
  admin = 'admin',
  adminLimit = 'admin_limit',
  user = 'user',
}

export type ApiUser = {
  email: string;
  first_name: string;
  last_name: string;
  // В описании api нет этого поля, надо уточнить
  avatar_url: string | null;
  role: UserRole;
  id: number;
  is_staff: boolean;
  is_active: boolean;
};

export interface IUser {
  email: string;
  firstName: string;
  lastName: string;
  // В описании api нет этого поля, надо уточнить
  avatarUrl: string | null;
  role: UserRole;
  id: number;
  isStaff: boolean;
  isActive: boolean;
}
