import cn from 'classnames';
import * as React from 'react';

import logo from 'assets/images/rsv-logo.svg';
import BaseClickable from 'components/ui/BaseClickable';
import Picture from 'components/ui/Picture';
import headerUrls from 'configs/headerUrls';

import s from './Logo.module.scss';

type LogoProps = {
  className?: string;
};

const Logo: React.FC<LogoProps> = ({ className }) => {
  return (
    <BaseClickable className={cn(s.logo, className)} element="a" href={headerUrls.main}>
      <Picture
        className={s.logo__image}
        src={logo}
        alt="Логотип Россия страна возможностей 5 лет"
        width={156}
        height={100}
      />
    </BaseClickable>
  );
};

export default React.memo(Logo);
