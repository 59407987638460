import cn from 'classnames';
import * as React from 'react';

import { SocialIconProps } from '../types';

import s from './RutubeIcon.module.scss';

export const RutubeIcon: React.FC<SocialIconProps> = React.memo(({ view, className }) => {
  return (
    <svg
      className={cn(s.rutubeIcon, s[`rutube-icon_${view}`], className)}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className={s.rutubeIcon__backgroundAccent} cx="16" cy="16" r="16" fill="currentColor" />
      <path
        className={s.rutubeIcon__background}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M32 15.9997C32 15.9998 32 15.9999 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C16.0334 0 16.0667 0.00010208 16.1 0.000305944C16.1002 8.80336 23.2096 15.9459 32 15.9997Z"
      />
      <path
        fill="currentColor"
        d="M19.8633 15.2695H10.4094V11.5284H19.8633C20.4155 11.5284 20.7995 11.6247 20.9922 11.7928C21.1849 11.9608 21.3043 12.2726 21.3043 12.728V14.0713C21.3043 14.5511 21.1849 14.8629 20.9922 15.031C20.7995 15.1991 20.4155 15.2709 19.8633 15.2709V15.2695ZM20.5119 8.00143H6.40002V24H10.4094V18.795H17.7982L21.3043 24H25.794L21.9284 18.7706C23.3536 18.5594 23.9935 18.1227 24.5212 17.4029C25.049 16.6831 25.3137 15.5324 25.3137 13.9966V12.797C25.3137 11.8861 25.2172 11.1664 25.049 10.6147C24.8808 10.063 24.5932 9.5832 24.1848 9.15219C23.7533 8.74419 23.273 8.45685 22.6963 8.26434C22.1197 8.09627 21.3992 8 20.5119 8V8.00143Z"
      />
    </svg>
  );
});
