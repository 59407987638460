import { RSV_ADMIN_URL, RSV_FRONT_URL } from './env';

const url = `${RSV_FRONT_URL}/`;

export default {
  admin: RSV_ADMIN_URL,
  edu: `${url}edu/courses`,
  competitions: `${url}competitions`,
  success: `${url}success`,
  trud: 'https://trudvsem.ru/',
  old: 'https://old.rsv.ru/',
  about: `${url}about`,
  aboutUs: `${url}about-us`,
  contacts: `${url}contacts`,
  chat: `${url}account/chat`,
  user: `${url}account/main`,
  news: `${url}news`,
  supervisory: `${url}supervisory-board`,
  search: `${url}main/opportunities`,
  main: url,
  gallery: `${url}gallery`,
  otherSkills: `${url}other-skills/`,
  testing: `${url}testing/`,
  blog: `${url}blog/`,
  courses: `${url}edu/courses`,
  webinars: `${url}edu/webinars`,
  eduNavigation: `${url}education-info/navigation/`,
  seneg: 'https://senezh.rsv.ru/',
  documents: `${url}documents`,
};
