import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'configs/routes';

const RootRedirect: React.FC = () => {
  const navigate = useNavigate();

  React.useLayoutEffect(() => {
    navigate(ROUTES.constructor.courseList.getPath());
  }, [navigate]);

  return null;
};

export default RootRedirect;
