import cn from 'classnames';
import * as React from 'react';

import Loader from 'components/ui/Loader';

import s from './LoadingStub.module.scss';

type LoadingStubProps = {
  className?: string;
};

const LoadingStub: React.FC<LoadingStubProps> = ({ className }) => {
  return (
    <div className={cn(s['loading-stub'], className)}>
      <Loader />
    </div>
  );
};

export default React.memo(LoadingStub);
