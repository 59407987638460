type CoursePathParams = {
  courseId: number;
  lessonId?: number;
};

const getConstructorPrefix = (path: string) => `/constructor/${path}`;

export const ROUTES = {
  root: '/',
  auth: '/auth',
  loginRedirect: '/oauth',
  logoutRedirect: {
    mask: '/logout',
    getPath: () => '/logout',
  },
  notFound: '/404',
  course: {
    root: {
      mask: '/courses/:courseId/*',
      getPath: (courseId: number) => `/courses/${courseId}`,
    },
    inner: {
      mask: 'lesson/:lessonId?',
      getPath: ({ courseId, lessonId }: CoursePathParams) => `/courses/${courseId}/lesson/${lessonId}`,
    },
    scorm: {
      mask: 'scorm',
      getPath: (courseId: number) => `/courses/${courseId}/scorm`,
    },
  },
  constructor: {
    courseList: {
      mask: getConstructorPrefix('courses'),
      getPath: () => getConstructorPrefix('courses'),
    },
    chapterList: {
      mask: getConstructorPrefix('courses/:courseId'),
      getPath: (courseId: number) => getConstructorPrefix(`courses/${courseId}`),
    },
    lessonList: {
      mask: getConstructorPrefix('courses/:courseId/chapters/:chapterId'),
      getPath: (courseId: number, chapterId: number) =>
        getConstructorPrefix(`courses/${courseId}/chapters/${chapterId}`),
    },
    webinars: {
      // заглушка для сайдбара в списке курсов,
      mask: getConstructorPrefix('webinars'),
      getPath: () => getConstructorPrefix('webinars'),
    },
    chapter: {
      mask: getConstructorPrefix('courses/:courseId/:chapterId'),
      getPath: (courseId: number, chapterId: number) => getConstructorPrefix(`courses/${courseId}/${chapterId}`),
    },
    singleLesson: {
      mask: getConstructorPrefix('courses/:courseId/single-lesson/:lessonId'),
      getPath: (courseId: number, lessonId: number) =>
        getConstructorPrefix(`courses/${courseId}/single-lesson/${lessonId}`),
    },
    lesson: {
      mask: getConstructorPrefix('courses/:courseId/lessons/:lessonId'),
      getPath: (courseId: number, lessonId: number) => getConstructorPrefix(`courses/${courseId}/lessons/${lessonId}`),
    },
  },
};
