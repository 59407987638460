import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import queryString from 'query-string';

import { AnyObject } from 'types/UtilityTypes';
import { logError } from 'utils/logError';

import { ApiCallParams, ApiErrorResponse, BaseResponse, RequestOptions, RequestParams } from './types';

const normalizeStringData = (raw: string): string | AnyObject => {
  try {
    const response = JSON.parse(raw);
    const isStringData = typeof response.data === 'string';

    if (isStringData) {
      const data = JSON.parse(response.data);

      return {
        ...response,
        data,
      };
    }

    return response;
  } catch (e) {
    return raw;
  }
};

const callApi = async <ResponseData>({
  endpoint: url,
  method = 'GET',
  config = {},
  abortController,
}: ApiCallParams<ResponseData>): Promise<BaseResponse<ResponseData>> => {
  try {
    const response = await axios({
      url,
      method,
      transformResponse: [normalizeStringData],
      signal: abortController?.signal,
      ...config,
    });

    const { data } = response.data;

    return {
      isError: false,
      data,
    };
  } catch (error) {
    return {
      isError: true,
      error: error as AxiosError<ApiErrorResponse>,
    };
  }
};

const configApi = ({
  method = 'GET',
  data = {},
  config = {},
  multipartFormData = false,
}: RequestOptions): AxiosRequestConfig => {
  const queryConfig = { ...config };

  if ((queryConfig.data === null || queryConfig.data === undefined) && method.toUpperCase() !== 'GET') {
    queryConfig.data = data;
  }

  if (!queryConfig.headers) {
    queryConfig.headers = {};
  }

  if (multipartFormData) {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (Array.isArray(value)) {
        value.forEach((item) => {
          formData.append(key, item);
        });
      } else {
        formData.append(key, value);
      }
    });
    queryConfig.data = formData;

    Object.assign(queryConfig.headers, {
      'Content-Type': 'multipart/form-data',
    });
  }

  if (method.toUpperCase() === 'GET') {
    queryConfig.params = {
      ...data,
    };

    queryConfig.paramsSerializer = {
      ...queryConfig.paramsSerializer,
      serialize: (params) => queryString.stringify(params, { skipNull: true }),
    };
  }

  return queryConfig;
};

const call = async <ResponseData>(params: RequestParams<ResponseData>): Promise<BaseResponse<ResponseData>> => {
  const { endpoint, method, data, config, multipartFormData, mockResponse, abortController } = params;

  if (mockResponse) {
    return mockResponse;
  }

  try {
    return await callApi<ResponseData>({
      endpoint,
      method,
      config: configApi({ method, data, config, multipartFormData }),
      abortController,
    });
  } catch (err) {
    const error = err as AxiosError<ApiErrorResponse>;
    const errorStatus = error?.response?.status;
    const logMessage = `[API]: ${method} ${endpoint} END ERROR ${errorStatus}`;

    logError(logMessage);

    return {
      isError: true,
      error,
    };
  }
};

export default call;
