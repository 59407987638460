import AppStoreIcon from 'components/ui/icons/mobileAppIcons/AppStoreIcon';
import RuStoreIcon from 'components/ui/icons/mobileAppIcons/RuStoreIcon';

import { MobileApp, MobileAppType } from './types';

export const mobileApps: Record<MobileAppType, MobileApp> = {
  [MobileAppType.apple]: {
    url: 'https://apps.apple.com/ru/app/%D1%80%D0%BE%D1%81%D1%81%D0%B8%D1%8F-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B0-%D0%B2%D0%BE%D0%B7%D0%BC%D0%BE%D0%B6%D0%BD%D0%BE%D1%81%D1%82%D0%B5%D0%B9/id6443535931',
    Icon: AppStoreIcon,
  },
  [MobileAppType.ruStore]: {
    url: 'https://apps.rustore.ru/app/ru.rsv.rsv_platforma_flutter',
    Icon: RuStoreIcon,
  },
};
