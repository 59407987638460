import * as Sentry from '@sentry/react';
import { configure } from 'mobx';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { SENTRY_DSN, SENTRY_ENVIRONMENT } from 'configs/env';
import Root from 'pages/Root';

import 'styles/globalStyles.scss';

configure({
  enforceActions: 'always',
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: true,
  useProxies: 'ifavailable',
});

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    tracesSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
  });
}

createRoot(document.getElementById('root') as HTMLElement).render(<Root />);
