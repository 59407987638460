import cn from 'classnames';
import * as React from 'react';

import Typo from '../Typo';

import s from './Alert.module.scss';

type AlertProps = {
  className?: string;
  children: React.ReactNode;
  view: 'info' | 'error';
};

const Alert: React.FC<AlertProps> = ({ className, children, view }) => {
  return (
    <Typo view="body-3" weight="medium" className={cn(s.root, s[`root_view_${view}`], className)}>
      {children}
    </Typo>
  );
};

export default Alert;
