import { RSV_CMS_URL } from './env';

const getApiPrefix = (endpoint: string) => `/api/${endpoint}`;
const getUserPrefix = (endpoint: string) => getApiPrefix(`user.${endpoint}`);
const getChunksPrefix = (endpoint: string): string => getApiPrefix(`chunk.${endpoint}`);
const getLessonPrefix = (endpoint: string): string => getApiPrefix(`lesson.${endpoint}`);
const getUnitPrefix = (endpoint: string): string => getApiPrefix(`unit.${endpoint}`);
const getChapterPrefix = (endpoint: string) => `${getApiPrefix('chapter.')}${endpoint}`;
const getCoursePrefix = (endpoint: string) => `${getApiPrefix('course.')}${endpoint}`;
const getAssessmentPrefix = (endpoint: string) => `${getApiPrefix('assessment.')}${endpoint}`;
const getExternalTestPrefix = (endpoint: string) => `${getAssessmentPrefix('external_tests.')}${endpoint}`;

const getAttachmentPrefix = (endpoint: string): string => getApiPrefix(`attachment.${endpoint}`);

const getRSVApiPrefix = (endpoint: string) => `${RSV_CMS_URL}/entity/select/page?name=${endpoint}`;

export const ENDPOINTS = {
  user: {
    current: getUserPrefix('current'),
    /** Получение url-адреса oauth */
    auth: getUserPrefix('auth'),
    /** Отправляем код oauth, получаем куки */
    login: getUserPrefix('code_login'),
    logout: getUserPrefix('logout'),
  },
  lesson: {
    list: getLessonPrefix('list'),
    get: getLessonPrefix('get'),
    create: getLessonPrefix('create'),
    edit: getLessonPrefix('edit'),
    swap: getLessonPrefix('swap'),
    rearrangement: getLessonPrefix('rearrangement'),
    delete: getLessonPrefix('delete'),
    copy: getLessonPrefix('copy'),
    import: getLessonPrefix('import'),
  },
  unit: {
    create: getUnitPrefix('create'),
    edit: getUnitPrefix('edit'),
    get: getUnitPrefix('get'),
    list: getUnitPrefix('list'),
    delete: getUnitPrefix('delete'),
    fullScrolled: getUnitPrefix('full_scrolled'),
  },
  chunk: {
    saveMany: getChunksPrefix('save_many'),
    setTimeCode: getChunksPrefix('set_timecode'),
  },
  attachment: {
    upload: getAttachmentPrefix('upload'),
    confirm: getAttachmentPrefix('upload_confirm'),
    get: getAttachmentPrefix('get'),
    commentList: getAttachmentPrefix('comment.list'),
    commentCreate: getAttachmentPrefix('comment.create'),
    commentUpdate: getAttachmentPrefix('comment.update'),
    commentDelete: getAttachmentPrefix('comment.delete'),
  },
  course: {
    get: getCoursePrefix('get'),
    list: getCoursePrefix('list'),
    create: getCoursePrefix('create'),
    edit: getCoursePrefix('edit'),
    delete: getCoursePrefix('delete'),
    copy: getCoursePrefix('copy'),
    size: getCoursePrefix('size'),
    complete: getCoursePrefix('complete'),
    scormUploadStatus: getCoursePrefix('scorm_upload_status'),
    setScormSource: getCoursePrefix('set_scorm_source'),
    setScormProgress: getCoursePrefix('set_scorm_progress'),
    getScormProgress: getCoursePrefix('get_scorm_progress'),
    createFeedback: getCoursePrefix('feedback.create'),
    feedbackList: getCoursePrefix('feedback.list'),
  },
  chapter: {
    create: getChapterPrefix('create'),
    edit: getChapterPrefix('edit'),
    get: getChapterPrefix('get'),
    list: getChapterPrefix('list'),
    delete: getChapterPrefix('delete'),
    swap: getChapterPrefix('swap'),
    rearrangement: getChapterPrefix('rearrangement'),
    copy: getChapterPrefix('copy'),
  },
  assessment: {
    questionCreate: getAssessmentPrefix('question.create'),
    questionUpdate: getAssessmentPrefix('question.update'),
    deleteQuestion: getAssessmentPrefix('question.delete'),
    questionAdminGet: getAssessmentPrefix('test.question.get'),
    questionGet: getAssessmentPrefix('question.get'),
    questionAnswer: getAssessmentPrefix('answer'),
    questionResult: getAssessmentPrefix('result'),
  },
  externalTest: {
    list: getExternalTestPrefix('list'),
    url: getExternalTestPrefix('get_url'),
    complete: getExternalTestPrefix('mark_completed'),
  },
  rsv: {
    contacts: getRSVApiPrefix('contacts'),
  },
};
