import * as React from 'react';

import { RSV_FRONT_URL } from 'configs/env';

const RSVRedirect: React.FC = () => {
  React.useLayoutEffect(() => {
    window.location.href = RSV_FRONT_URL;
  }, []);

  return null;
};

export default RSVRedirect;
