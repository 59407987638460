export const getRem = (pixels: number): string => {
  return `${(pixels * 100) / 1920}rem`;
};

export const getRemTablet = (pixels: number): string => {
  return `${(pixels * 100) / 768}rem`;
};

export const getRemMobile = (pixels: number): string => {
  return `${(pixels * 100) / 375}rem`;
};
