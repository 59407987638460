import * as Sentry from '@sentry/react';

/** Функция для обработки ошибок внутри try/catch блоков */
export const logError = (message: string): void => {
  /* eslint-disable no-console */
  console.error(message);

  const error = new Error(message);

  error.name = 'CustomError';

  Sentry.captureException(error);
};
