import cn from 'classnames';
import * as React from 'react';

import { SocialIconProps } from '../types';

import s from './DzenIcon.module.scss';

export const DzenIcon: React.FC<SocialIconProps> = React.memo(({ view, className }) => {
  return (
    <svg
      className={cn(s.dzenIcon, s[`dzen-icon_${view}`], className)}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className={s.dzenIcon__background} cx="20" cy="20" r="20" fill="currentColor" />
      <path
        fill="currentColor"
        d="M20.3188 37.0535C20.5455 29.5739 21.4121 26.3874 23.8653 23.8809C26.3185 21.3744 29.5583 20.5478 37.4112 20.3211L39.4911 20.2411C39.5798 20.2335 39.669 20.2335 39.7577 20.2411H39.9844C39.9844 20.1745 39.9844 20.1211 39.9844 20.0545C39.9844 19.9878 39.9844 19.8812 39.9844 19.8012L37.8112 19.7345C36.6246 19.7345 35.4246 19.6545 35.1447 19.6412L33.7447 19.5478C28.9183 19.2545 26.0518 18.2946 23.972 16.2947C22.6422 14.9704 21.6935 13.3124 21.2255 11.495C20.6123 8.59319 20.3084 5.63462 20.3188 2.66881C20.3188 1.6422 20.2522 0.615591 20.2255 0.40227V0.015625H19.7589V0.908907C19.7589 1.85552 19.6255 5.02867 19.5322 6.24193C19.1322 11.575 18.199 14.0282 16.0524 16.1614C14.779 17.4053 13.1952 18.2848 11.466 18.7079C10.8927 18.8545 10.7461 18.8812 9.66611 19.0812C6.79661 19.4577 3.90719 19.6625 1.01328 19.6945H0C0 19.7878 0 19.8812 0 19.9745C0 20.0678 0 20.1078 0 20.1745H0.893282L4.0531 20.3078C10.3461 20.5878 13.0659 21.2811 15.4524 23.2143C17.3269 24.8475 18.5641 27.0905 18.9456 29.5473C19.4096 32.2785 19.6504 35.0431 19.6655 37.8135L19.7455 40H20.1855V39.4267C20.2522 39.0934 20.2922 38.0268 20.3188 37.0535Z"
      />
    </svg>
  );
});
