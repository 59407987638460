import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import ErrorStub from 'components/shared/auth/ErrorStub';
import LoadingStub from 'components/ui/LoadingStub';
import { LocalStorageKey } from 'configs/ls';
import { ROUTES } from 'configs/routes';
import { useLocalStore } from 'hooks/useLocalStore';
import { AuthPageStore } from 'stores/local/auth';

const AuthPage: React.FC = () => {
  const { pathname } = useLocation();
  const { fetchOAuthUrl, oauthUrl, meta } = useLocalStore(() => new AuthPageStore());

  React.useEffect(() => {
    if (oauthUrl) {
      if (pathname !== ROUTES.auth) {
        window.localStorage.setItem(LocalStorageKey.authRedirect, pathname);
      }
      window.location.href = oauthUrl;
    }
  }, [oauthUrl, pathname]);

  return meta.isError ? <ErrorStub redirect={fetchOAuthUrl} /> : <LoadingStub />;
};

export default observer(AuthPage);
