import * as Sentry from '@sentry/react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { ENDPOINTS } from 'configs/endpoints';
import { UserModel, ApiUser, IUser } from 'stores/models/user';
import { MetaStore } from 'stores/shared/MetaStore';
import call from 'utils/api';

export class UserStore {
  readonly currentUserMeta = new MetaStore();
  readonly logoutMeta = new MetaStore();

  user: UserModel | null = null;

  constructor() {
    makeObservable(this, {
      user: observable.ref,
      isAuthorized: computed,
      isAuthorizing: computed,
      setUser: action.bound,
      clearUser: action.bound,
      fetchUser: action.bound,
      logout: action.bound,
    });
  }

  get isAuthorized(): boolean {
    return this.user !== null;
  }

  get isAuthorizing(): boolean {
    if (this.user) {
      return false;
    }

    return this.currentUserMeta.isInitialMetaState || this.currentUserMeta.isLoading;
  }

  async fetchUser(): Promise<void> {
    this.currentUserMeta.setLoadedStartMeta();

    const response = await call<ApiUser>({ endpoint: ENDPOINTS.user.current });

    if (response.isError) {
      this.currentUserMeta.setLoadedErrorMeta();

      return;
    }

    runInAction(() => {
      this.setUser(UserModel.normalizeApi(response.data));
      this.currentUserMeta.setLoadedSuccessMeta();
    });
  }

  setUser(user: IUser): void {
    this.user = new UserModel(user);

    Sentry.configureScope((scope) => {
      scope.setUser(user);
    });
  }

  clearUser(): void {
    this.user = null;
    Sentry.setUser(null);
  }

  async logout(): Promise<void> {
    this.logoutMeta.setLoadedStartMeta();

    const response = await call<ApiUser>({ endpoint: ENDPOINTS.user.logout, method: 'POST' });

    if (response.isError) {
      this.logoutMeta.setLoadedErrorMeta();

      return;
    }

    runInAction(() => {
      this.clearUser();
      this.logoutMeta.setLoadedSuccessMeta();
    });
  }

  destroy(): void {
    this.currentUserMeta.destroy();
    this.logoutMeta.destroy();
  }
}
