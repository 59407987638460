import * as React from 'react';

import BaseClickable from 'components/ui/BaseClickable';
import { BurgerIcon } from 'components/ui/icons/BurgerIcon';

import s from './MenuButton.module.scss';

type MenuButtonProps = {
  onClick: VoidFunction;
};

const MenuButton: React.FC<MenuButtonProps> = ({ onClick }) => {
  return (
    <BaseClickable className={s.root} onClick={onClick}>
      <BurgerIcon className={s.root__icon} />
    </BaseClickable>
  );
};

export default React.memo(MenuButton);
