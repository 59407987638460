import * as React from 'react';

import BaseClickable from 'components/ui/BaseClickable';
import Typo from 'components/ui/Typo';

import s from './LegalItem.module.scss';

export type LegalItemProps = {
  url: string;
  text: string;
};

const LegalItem: React.FC<LegalItemProps> = ({ url, text }) => {
  return (
    <BaseClickable element="a" href={url}>
      <Typo className={s.legalItem} element="p" view="body-3" color="white">
        {text}
      </Typo>
    </BaseClickable>
  );
};

export default React.memo(LegalItem);
