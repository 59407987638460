import * as Sentry from '@sentry/react';
import * as React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import AssistentWidget from 'components/shared/AssistentWidget';
import SnackBar from 'components/shared/SnackBar';
import Layout from 'components/ui/Layout';
import { PortalContainerProvider } from 'components/ui/Portal';
import Suspense from 'components/ui/Suspense';
import { ROUTES } from 'configs/routes';
import { withAdmin } from 'hocs/withAdmin';
import { withAuth } from 'hocs/withAuth';
import RSVRedirect from 'pages/RSVRedirect';
import RootRedirect from 'pages/RootRedirect';
import LogoutRedirectPage from 'pages/auth/LogoutRedirectPage';
import QpParamsStoreInitializer from 'stores/global/qpStore/QpParamsStoreConnector';
import { WidthProvider } from 'utils/devices';

const AuthPage = React.lazy(() => import('../auth/AuthPage'));
const LoginRedirectPage = React.lazy(() => import('../auth/LoginRedirectPage'));

const ConstructorPageCourses = withAuth(withAdmin(React.lazy(() => import('../constructor/ConstructorPageCourses'))));
const ConstructorPageChapters = withAuth(withAdmin(React.lazy(() => import('../constructor/ConstructorPageChapters'))));
const ConstructorPageLesson = withAuth(withAdmin(React.lazy(() => import('../constructor/ConstructorPageLesson'))));

const CoursePage = withAuth(React.lazy(() => import('../CoursePage')));

const RootAdminRedirect = withAuth(withAdmin(RootRedirect));

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Root: React.FC = () => {
  // const [isRedirect] = React.useState(
  //   () => import.meta.env.PROD && !document.cookie.split(';').some((item) => item.trim().startsWith('RSV_DEBUG=true'))
  // );

  // React.useLayoutEffect(() => {
  //   if (isRedirect) {
  //     window.location.href = 'https://rsv.ru/maintenance';
  //   }
  // }, [isRedirect]);

  // if (isRedirect) {
  //   return null;
  // }

  return (
    <BrowserRouter>
      <QpParamsStoreInitializer>
        <WidthProvider>
          <PortalContainerProvider>
            <Layout>
              <Suspense withLoader>
                <SentryRoutes>
                  <Route path={ROUTES.root}>
                    <Route path={ROUTES.auth} element={<AuthPage />} />
                    <Route path={ROUTES.loginRedirect} element={<LoginRedirectPage />} />
                    <Route path={ROUTES.logoutRedirect.mask} element={<LogoutRedirectPage />} />

                    <Route path={ROUTES.constructor.lesson.mask} element={<ConstructorPageLesson />} />
                    <Route path={ROUTES.constructor.singleLesson.mask} element={<ConstructorPageLesson />} />

                    <Route path={ROUTES.constructor.courseList.mask} element={<ConstructorPageCourses />} />
                    <Route path={ROUTES.constructor.chapterList.mask} element={<ConstructorPageChapters />} />

                    <Route path={ROUTES.course.root.mask} element={<CoursePage />} />

                    <Route index element={<RootAdminRedirect />} />
                    <Route path={ROUTES.notFound} element={<RSVRedirect />} />
                    <Route path="*" element={<Navigate to={ROUTES.notFound} replace />} />
                  </Route>
                </SentryRoutes>
              </Suspense>
            </Layout>
          </PortalContainerProvider>
        </WidthProvider>
      </QpParamsStoreInitializer>
      <SnackBar />
      <AssistentWidget />
    </BrowserRouter>
  );
};

export default React.memo(Root);
