import * as React from 'react';

type UseCloseOnEscapeParams = {
  open: boolean;
  onClose: VoidFunction;
  ref?: React.RefObject<any>;
};

export const useCloseOnEscape = ({ open, onClose, ref }: UseCloseOnEscapeParams) => {
  const target = ref ? ref.current : document;

  React.useEffect(() => {
    if (!open) {
      return;
    }

    const KeyDown = (e: KeyboardEvent): void => {
      if (e.keyCode === 27) {
        onClose();
      }
    };

    target.addEventListener('keydown', KeyDown);

    return (): void => {
      target.removeEventListener('keydown', KeyDown);
    };
  }, [target, onClose, open]);
};
