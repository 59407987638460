import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import BaseClickable from 'components/ui/BaseClickable';
import { useRem } from 'hooks/useRem';
import { OpenStore } from 'stores/shared/OpenStore';
import { useWidth } from 'utils/devices';

import { CloseCircleIcon } from '../svgs/CloseCircleIcon';
import { VeraIcon } from '../svgs/VeraIcon';
import { WidgetIcon } from '../svgs/WidgetIcon';

import s from './PictureWrapper.module.scss';

type PictureWrapperProps = {
  widgetController: OpenStore;
  buttonsController: OpenStore;
};

const PictureWrapper: React.FC<PictureWrapperProps> = ({ widgetController, buttonsController }) => {
  const calcRem = useRem();
  const { isDesktop } = useWidth();

  if (isDesktop) {
    return (
      <div className={cn(s.root, !widgetController.isOpen && s.root_hide)}>
        {widgetController.isOpen ? (
          <BaseClickable onClick={buttonsController.toggle} block>
            <VeraIcon className={s.root__assistant} />
          </BaseClickable>
        ) : (
          <BaseClickable className={s['root__open-button']} onClick={widgetController.open} block>
            <WidgetIcon className={s['root__chat-icon']} />
          </BaseClickable>
        )}
      </div>
    );
  }

  return (
    <BaseClickable className={cn(s.root, s['root_mobile'])} onClick={buttonsController.toggle}>
      {buttonsController.isOpen ? (
        <CloseCircleIcon size={calcRem({ desktop: 30 })} />
      ) : (
        <WidgetIcon size={calcRem({ desktop: 24 })} />
      )}
    </BaseClickable>
  );
};

export default observer(PictureWrapper);
