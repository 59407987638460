import { FC } from 'react';

import { SocialIconProps } from 'components/ui/icons/socialIcons/types';

export enum SocialNetworkType {
  vk = 'vk',
  ok = 'ok',
  youtube = 'youtube',
  telegram = 'telegram',
  rutube = 'rutube',
  yappi = 'yappi',
  dzen = 'dzen',
  tenchat = 'tenchat',
}

export type SocialNetwork = {
  url: string;
  Icon: FC<SocialIconProps>;
};
