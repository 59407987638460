import cn from 'classnames';
import * as React from 'react';

import s from './Spinner.module.scss';

export type SpinnerProps = {
  className?: string;
};

const Spinner: React.FC<SpinnerProps> = ({ className }) => {
  return <span className={cn(s.root, className)} />;
};

export default React.memo(Spinner);
